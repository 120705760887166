/**
 * @file Contains generic diff helper function
 * */

import { isEqual } from 'es-toolkit'

/**
 * Returns an object containing only the properties that differ between two objects.
 *
 * Requirements:
 * 1. Both objects must share the same structure/shape (same property keys)
 * 2. newObj should not contain additional properties that don't exist in currentObj
 *
 * @param currentObj The current/original object to compare against
 * @param newObj The new object containing potential changes
 * @returns An object containing only the properties from newObj that differ from currentObj
 *
 * @example
 * const current = { name: "John", age: 30 };
 * const updated = { name: "John", age: 31 };
 * getDiff(current, updated) // Returns: { age: 31 }
 */
function getDiff<T extends object>(currentObj: Partial<T>, newObj: Partial<T>): Partial<T> {
  const diff: Partial<T> = {}

  for (const key in currentObj) {
    const typedKey = key as keyof T
    if (!isEqual(currentObj[typedKey], newObj[typedKey])) {
      diff[typedKey] = newObj[typedKey]
    }
  }

  return diff
}

export { getDiff }
